<template>
  <div
    class="order-a4-container pages-сontainer max-w-[794px] mx-auto min-h-max text-black hidden"
    id="order-a4-container"
  >
    <div
      class="page relative w-[794px] h-[1122px] mx-auto bg-white"
      v-for="(page, index) in splittedData"
      :id="`page-${index + 1}`"
    >
      <!--header-->
      <div class="px-[35px] pt-[30px] pb-[5px] header">
        <div class="flex flex-col gap-[5px]">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p class="text-[14px] leading-[20px] font-bold">Orders</p>
            </div>
            <!--logo-->
            <div
              v-if="
                generalStore.printAssets?.print_logo &&
                generalStore.printAssets?.print_logo?.split('base64,')?.[1]
              "
              id="logo-container"
              class="h-[54px] max-h-[54px] min-h-[54px]"
            >
              <!--              :src="generalStore.printAssets?.print_logo"-->
              <img
                v-if="
                  generalStore.printAssets?.print_logo &&
                  generalStore.printAssets?.print_logo?.split('base64,')?.[1]
                "
                id="logo-image"
                :src="generalStore.printAssets.print_logo"
                alt="logo"
                class="max-h-[54px] h-[54px] min-h-[54px] object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- orders list-->
      <div class="px-[35px]">
        <div class="border-b border-black mt-[5px]"></div>
        <table>
          <thead>
            <tr class="border-b border-black">
              <th class="min-w-[120px] py-[5px]">
                <p
                  class="min-w-[120px] text-left text-[10px] leading-[18px] font-bold"
                >
                  Customer
                </p>
              </th>
              <th class="min-w-[90px] py-[5px]">
                <p
                  class="min-w-[90px] text-left text-[10px] leading-[18px] font-bold"
                >
                  Order
                </p>
              </th>
              <th class="min-w-[40px] py-[5px] pl-[5px] w-full">
                <p
                  class="min-w-[40px] text-left text-[10px] leading-[18px] font-bold w-full"
                >
                  Status
                </p>
              </th>
              <th class="py-[5px] pl-[5px] w-full min-w-[80px]">
                <p
                  class="text-left text-[10px] leading-[18px] font-bold w-full min-w-[80px]"
                >
                  Target Date
                </p>
              </th>
              <th class="min-w-[160px] py-[5px] pl-[5px]">
                <p
                  class="min-w-[160px] text-left text-[10px] leading-[18px] font-bold"
                >
                  Address
                </p>
              </th>
              <th class="min-w-[60px] py-[5px] pl-[5px]">
                <p
                  class="min-w-[60px] text-right text-[10px] leading-[18px] font-bold"
                >
                  Total
                </p>
              </th>
              <th class="min-w-[20px] py-[5px] pl-[5px]">
                <p
                  class="min-w-[20px] text-left text-[10px] leading-[18px] font-bold"
                >
                  Fulfilment
                </p>
              </th>
              <th class="min-w-[60px] py-[5px] pl-[5px]">
                <p
                  class="min-w-[60px] text-left text-[10px] leading-[18px] font-bold"
                >
                  Tag
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in page"
              :class="{
                'border-b border-black': page?.length - 1 !== i,
              }"
            >
              <td class="min-w-[120px] py-[5px]">
                <p
                  class="min-h-[19.5px] h-[19.5px] min-w-[110px] max-w-[110px] text-[10px] leading-[14px] font-medium whitespace-nowrap overflow-x-hidden"
                >
                  {{ item?.customer_name || "Guest" }}
                </p>
              </td>
              <td class="min-w-[90px] py-[5px]">
                <p
                  class="min-h-[19.5px] h-[19.5px] min-w-[90px] text-[10px] leading-[18px] font-medium"
                >
                  {{
                    useGeneralStore().orderTypeList.find(
                      (ot) => ot.value === item?.type
                    )?.name +
                    " #" +
                    item?.id
                  }}
                </p>
              </td>
              <td class="min-w-[40px] py-[5px] w-full">
                <p
                  class="min-h-[20px] h-[20px] min-w-[40px] text-left text-[10px] leading-[18px] font-medium pl-[5px] w-full"
                >
                  {{
                    useOrdersStore().statusTags.find(
                      (status) => status.value === item.status
                    )?.name
                  }}
                </p>
              </td>
              <td class="py-[5px] w-full min-w-[80px] w-full overflow-x-hidden">
                <p
                  class="min-h-[19.5px] h-[19.5px] text-left text-[10px] leading-[18px] font-medium w-full pl-[5px] min-w-[80px]"
                >
                  {{ useDateFormatLib(item?.target_date, "dd mmm, yyyy") }}
                </p>
              </td>
              <td class="min-w-[160px] max-w-[160px] py-[5px]">
                <p
                  class="min-h-[19.5px] h-[19.5px] min-w-[160px] max-w-[160px] text-left text-[10px] leading-[14px] font-medium pl-[5px] whitespace-nowrap overflow-x-hidden"
                >
                  {{ item?.address }}
                </p>
              </td>
              <td class="min-w-[60px] py-[5px]">
                <p
                  class="min-h-[19.5px] h-[19.5px] min-w-[60px] text-right text-[10px] leading-[18px] font-medium pl-[5px]"
                >
                  {{ useFormatPrice(item?.total_value) }}
                </p>
              </td>
              <td class="min-w-[20px] py-[5px]">
                <p
                  class="min-h-[19.5px] h-[19.5px] min-w-[20px] text-left text-[10px] leading-[18px] font-medium pl-[5px]"
                >
                  {{ item?.fulfilment ? "Picked" : "" }}
                </p>
              </td>
              <td class="min-w-[60px] py-[5px]">
                <p
                  class="min-h-[19.5px] h-[19.5px] min-w-[60px] text-left text-[10px] leading-[18px] font-medium pl-[5px]"
                >
                  {{ item?.tag_name }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--footer-->
      <div class="absolute bottom-0 left-0 w-full">
        <div class="border-b border-black"></div>
        <div
          class="flex justify-between px-[35px] pt-[7.5px] min-h-[50px] text-[10px] leading-[15px] font-medium"
        >
          <p>Orders</p>
          <p>Page {{ index + 1 }} of {{ totalPages }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useTasksStore } from "~/store/tasks";
import { useReportsStore } from "~/store/reports";
import { OrdersReport } from "~/types/reports";
import { useGeneralStore } from "~/store/general";
import { useOrdersStore } from "../../../store/orders";

const emit = defineEmits(["loaded"]);

const router = useRouter();

const generalStore = useGeneralStore();
const tasksStore = useTasksStore();
const reportsStore = useReportsStore();

const task = ref(null);
const totalPages = ref(1);

const headerHeight = ref(0);
const customerHeight = ref(0);

const orderNoticeHeight = ref(0);
const paymentDetailsHeight = ref(0);
const bottomBlockHeight = ref(0);

const firstPageContentMaxHight = ref();
const otherPagesContentMaxHight = ref();

const splittedData = ref([]);

watch(
  () => reportsStore.viewingOrdersForPrint,
  async () => {
    splitTableData();
  }
);

function splitData(data, firstPageContent, otherPagesContent) {
  const result = [];
  let currentPage = [];
  let currentPageContent = firstPageContent;
  data.forEach((item) => {
    if (currentPageContent > 0) {
      currentPage.push(item);
      currentPageContent--;
    } else {
      if (currentPage.length > 0) {
        result.push(currentPage);
      }
      currentPage = [item];
      currentPageContent = otherPagesContent;
    }
  });
  if (currentPage.length > 0) {
    result.push(currentPage);
  }
  return result;
}

function splitTableData() {
  //header
  headerHeight.value = 0;
  customerHeight.value = 0;
  orderNoticeHeight.value = 0;
  paymentDetailsHeight.value = 0;
  bottomBlockHeight.value = 0;

  //max height for table rows on first page
  firstPageContentMaxHight.value =
    1252 - 185 - (headerHeight.value + customerHeight.value);

  //max height for table rows on other page
  otherPagesContentMaxHight.value = 1202 - 165 - headerHeight.value;

  if (
    reportsStore.viewingOrdersForPrint?.data?.length * 30 >=
    firstPageContentMaxHight.value
  ) {
    splittedData.value = splitData(
      [...reportsStore.viewingOrdersForPrint.data],
      Math.floor(firstPageContentMaxHight.value / 30),
      Math.floor(otherPagesContentMaxHight.value / 30)
    );
  } else {
    splittedData.value = [[...reportsStore.viewingOrdersForPrint?.data]];
    if (
      reportsStore.viewingOrdersForPrint?.data?.length * 30 +
        145 +
        headerHeight.value +
        customerHeight.value +
        orderNoticeHeight.value +
        bottomBlockHeight.value <
      1122
    ) {
      totalPages.value = 1;
    } else {
      totalPages.value = 2;
    }
    useIsLoading(false);
    return;
  }
  if (splittedData.value?.length === 1) {
    totalPages.value = 2;
  }
  if (
    splittedData.value?.length > 1 &&
    splittedData.value?.[splittedData.value?.length - 1]?.length * 30 +
      185 +
      bottomBlockHeight.value +
      headerHeight.value +
      orderNoticeHeight.value <
      1122
  ) {
    totalPages.value = splittedData.value?.length;
  } else {
    totalPages.value = splittedData.value?.length + 1;
  }
  useIsLoading(false);
}

onMounted(async () => {
  useIsLoading(true);

  setTimeout(() => {
    useIsLoading(false);
    emit("loaded");
  }, 1000);

  useIsLoading(false);
});

onUnmounted(() => {
  reportsStore.viewingOrdersForPrint = {} as OrdersReport;
});
</script>

<style lang="scss">
#statement-a4-container.pages-сontainer {
  margin: 102px auto;
}

#statement-a4-container .page {
  margin-top: 25px;
}

@page {
  size: a4;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Inter", sans-serif;
}

@media print {
  .pages-сontainer {
    margin: 0 !important;
    padding: 0 !important;
  }
  .page {
    overflow: hidden;
    box-shadow: none;
    margin: 0;
    background-color: white;
    display: block;
    transform-origin: top left;
    page-break-before: auto;
  }
}
</style>
